import React from "react";
import { FiPlus } from "react-icons/fi";
import { Link } from "react-router-dom";

const Dashbordleft = ({ handleOpenPopup, handleOpenPopup3 }) => {
  return (
    <div className="grid">
      <div
        onClick={handleOpenPopup}
        className=" cursor-pointer flex w-[200px] h-[70px] rounded-[15px] shadow-md bg-[#D4F5F9FF] justify-between items-center content-center px-6 py-6"
      >
        <FiPlus className="text-black font-[500] text-[20px]" />
        <Link className="text-black  font-[900]">FUND WALLET</Link>
      </div>

      <div
        onClick={handleOpenPopup3}
        className=" cursor-pointer grid gap-2 w-[150px] h-[200px] rounded-[15px] shadow-md bg-[#23292F]  place-items-center place-content-center px-6 py-6"
      >
        <FiPlus className="text-[#414C56] font-[500] text-[50px]" />
        <Link className="text-[#D4F5F9FF] font-[900] text-[20px]">INVEST</Link>
      </div>
    </div>
  );
};

export default Dashbordleft;
