import React from "react";
import { FaHome } from "react-icons/fa";
import { LiaGoogleWallet} from "react-icons/lia";
import { GrTransaction } from "react-icons/gr";
import { Link } from "react-router-dom";

const Mobilenav = () => {
  return (
    <nav className="fixed bottom-[0px] left-0 right-0 px-6 py-6">
      <ul className="flex justify-around items-center py-3 px-4 bg-[#000043] shadow-md border-t border-gray-200 rounded-[15px]">
        {/* Home */}
        <li>
          <Link
            to="/dashboard"
            className=" text-white hover:text-[#D4F5F9] grid place-items-center place-content-center gap-2"
          >
            <FaHome className="text-[30px]" />
            <span className="text-xs">Home</span>
          </Link>
        </li>
        {/* Invest */}
        <li>
          <Link
            to="/mobileinvestment"
            className="text-white hover:text-[#D4F5F9] grid place-items-center place-content-center gap-2"
          >
            <LiaGoogleWallet className="text-[30px]" />
            <span className="text-xs">Invest</span>
          </Link>
        </li>
        {/* Transaction */}
        <li>
          <Link
            to="/mobiletransaction"
            className="text-white hover:text-[#D4F5F9] grid place-items-center place-content-center gap-2"
          >
            <GrTransaction className="text-[30px]" />
            <span className="text-xs">Transaction</span>
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Mobilenav;
