import React, { useEffect, useState } from "react";
import axios from "axios";
import Lofo from "../../img/bitcoin.png";
import ethimg from "../../img/eth.png";
import bnbimg from "../../img/bnb.png";
import xrpimg from "../../img/xrp.png";


function Mobilecryptolisting() {
  const [cryptoRates, setCryptoRates] = useState({
    Bitcoin: { usd: null, image: Lofo }, // Add the image file names here
    Ethereum: { usd: null, image: ethimg },
    BnB: { usd: null, image: bnbimg },
    Ripple: { usd: null, image: xrpimg },
  });

  useEffect(() => {
    const fetchCryptoRates = async () => {
      try {
        const response = await axios.get(
          "https://api.coingecko.com/api/v3/simple/price?ids=bitcoin,ethereum,binancecoin,ripple&vs_currencies=usd"
        );
        if (response && response.data) {
          const updatedCryptoRates = {};
          Object.keys(response.data).forEach((crypto) => {
            const abbreviations = {
              bitcoin: "Bitcoin",
              ethereum: "Ethereum",
              binancecoin: "BnB",
              ripple: "Ripple",
            };
            const abbreviation = abbreviations[crypto];
            if (abbreviation) {
              updatedCryptoRates[abbreviation] = {
                usd: response.data[crypto].usd,
                image: cryptoRates[abbreviation].image,
              };
            }
          });
          setCryptoRates(updatedCryptoRates);
        } else {
          console.error("Invalid response data:", response);
        }
      } catch (error) {
        console.error("Error fetching cryptocurrency rates:", error);
      }
    };

    // Fetch cryptocurrency rates on component mount
    fetchCryptoRates();

    // Fetch cryptocurrency rates every 60 seconds (optional)
    const intervalId = setInterval(fetchCryptoRates, 60000);

    // Clean up interval
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className=" flex items-center w-full  p-6 mt-[70px] ">
      {Object.keys(cryptoRates).map(
        (crypto) =>
          // Check if the rate data is available
          cryptoRates[crypto] &&
          cryptoRates[crypto].usd && (
            <div
              key={crypto}
              className="grid place-items-center w-full py-2 place-content-center border-l border-white"
            >
              <div className="w-full">
                {cryptoRates[crypto].image && (
                  <img
                    src={cryptoRates[crypto].image}
                    alt={crypto}
                    className="w-[30px] md:w-[40px] h-[30px] md:h-[40px] mr-2"
                  />
                )}
              </div>
              <div className="w-full">
                <p className="text-[12px] md:text-[14px] text-left font-[istok] font-[900] text-[#000]">
                  {crypto}
                </p>
              </div>
              <div className="w-full">
                <p className="text-[14px] md:text-[16px] font-[istok] font-[500] text-[#4AE115]">
                  ${cryptoRates[crypto].usd}
                </p>
              </div>
            </div>
          )
      )}
    </div>
  );
}

export default Mobilecryptolisting;
