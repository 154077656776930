import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Lofo from "../img/bitcoin.png";
import ethimg from "../img/eth.png";
import bnbimg from "../img/bnb.png";
import xrpimg from "../img/xrp.png";

function Jumbotron() {
  const cryptoImages = {
    Bitcoin: Lofo,
    Ethereum: ethimg,
    BnB: bnbimg,
    Ripple: xrpimg,
  };

  const [cryptoRates, setCryptoRates] = useState({
    Bitcoin: { usd: null },
    Ethereum: { usd: null },
    BnB: { usd: null },
    Ripple: { usd: null },
  });

  useEffect(() => {
    const fetchCryptoRates = async () => {
      try {
        const response = await axios.get(
          "https://api.coingecko.com/api/v3/simple/price?ids=bitcoin,ethereum,binancecoin,ripple&vs_currencies=usd"
        );
        if (response && response.data) {
          const updatedCryptoRates = {};
          Object.keys(response.data).forEach((crypto) => {
            const abbreviations = {
              bitcoin: "Bitcoin",
              ethereum: "Ethereum",
              binancecoin: "BnB",
              ripple: "Ripple",
            };
            const abbreviation = abbreviations[crypto];
            if (abbreviation) {
              updatedCryptoRates[abbreviation] = {
                usd: response.data[crypto].usd,
              };
            }
          });
          setCryptoRates(updatedCryptoRates);
        } else {
          console.error("Invalid response data:", response);
        }
      } catch (error) {
        console.error("Error fetching cryptocurrency rates:", error);
      }
    };

    fetchCryptoRates();
    const intervalId = setInterval(fetchCryptoRates, 60000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div
      style={{
        backgroundColor: "#00001A",
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        overflowX: "hidden",
      }}
    >
      <div style={{ marginBottom: "20px", textAlign: "center" }}>
        <p
          style={{
            fontSize: "28px",
            color: "#D4F5F9",
            fontWeight: "bold",
            marginBottom: "10px",
          }}
        >
          Buy & Invest
        </p>
        <p
          style={{
            fontSize: "28px",
            color: "#fff",
            fontWeight: "bold",
            marginBottom: "10px",
          }}
        >
          Crypto Tokens Easily
        </p>
        <p
          style={{
            fontSize: "14px",
            color: "#fff",
            lineHeight: "1.5",
            marginBottom: "20px",
          }}
        >
          Emerald Capital is a crypto investment platform that enables both
          crypto newbies and pros to invest, maintain, and grow a portfolio of
          digital assets.
        </p>
        <div
          style={{
            backgroundColor: "#0096C7",
            color: "#00001A",
            borderRadius: "15px",
            padding: "10px 20px",
            fontWeight: "bold",
            cursor: "pointer",
          }}
        >
          <Link to="/signup" style={{ color: "#00001A", textDecoration: "none" }}>
            Get Started
          </Link>
        </div>
      </div>

      <div
        style={{
          width: "100%",
          overflow: "hidden",
          marginTop: "20px",
          display: "flex",
          justifyContent: "center",
          position: "relative",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "20px",
            animation: "slide 15s linear infinite",
            whiteSpace: "nowrap",
          }}
        >
          {Object.keys(cryptoRates).map(
            (crypto) =>
              cryptoRates[crypto] &&
              cryptoRates[crypto].usd && (
                <div
                  key={crypto}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    backgroundColor: "#E8EEF1",
                    borderRadius: "10px",
                    padding: "10px 20px",
                    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                    minWidth: "200px", // Adjust for mobile
                  }}
                >
                  <img
                    src={cryptoImages[crypto]}
                    alt={crypto}
                    style={{
                      width: "30px",
                      height: "30px",
                      marginRight: "10px",
                    }}
                  />
                  <div style={{ flex: 1 }}>
                    <p
                      style={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        marginBottom: "5px",
                      }}
                    >
                      {crypto}
                    </p>
                    <p
                      style={{
                        fontSize: "14px",
                        color: "#4AE115",
                        fontWeight: "bold",
                      }}
                    >
                      ${cryptoRates[crypto].usd}
                    </p>
                  </div>
                </div>
              )
          )}
        </div>
      </div>

      {/* Inline CSS for Keyframes */}
      <style>
        {`
          @keyframes slide {
            0% {
              transform: translateX(100%);
            }
            100% {
              transform: translateX(-100%);
            }
          }

          @media (max-width: 768px) {
            .crypto-cards-container {
              display: flex;
              gap: 20px;
              animation: slide 15s linear infinite;
              overflow: hidden;
              flex-wrap: nowrap;  /* Keep cards in a single row */
            }
            .crypto-card {
              min-width: 160px;  /* Adjust for mobile */
            }
            p {
              font-size: 12px;
            }
            img {
              width: 25px;
              height: 25px;
            }
          }
        `}
      </style>
    </div>
  );
}

export default Jumbotron;
