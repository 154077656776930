import React from "react";

import { FaUserCircle } from "react-icons/fa";
import { CiSettings } from "react-icons/ci";
import { Link } from "react-router-dom";

const Mobileheader = () => {
    const userid = JSON.parse(localStorage.getItem("userdata"));
  return (
    <div className="flex justify-between items-center content-center  px-6 py-6 ">
      <div className="flex justify-between gap-4 items-center content-center">
        <div>
          <FaUserCircle className="text-white text-[42px]" />
        </div>

        <div className="text-white font-[istok]">
          <h2> WELCOME</h2>
          <h2> {userid.fullname}</h2>
        </div>
      </div>

      <Link to="/mobilesettings" className=" cursor-pointer">
        <CiSettings className="text-[30px] text-white" />
      </Link>
    </div>
  );
};

export default Mobileheader;
