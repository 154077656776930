import React, {useState, useEffect} from "react";
import Dashboardsidenav from "../Dasboard/Dashboardsidenav";

import "react-toastify/dist/ReactToastify.css";
import Transactbody from "./Transactbody";
import axios from "axios";
import { url } from "../../api/api";



const Jumbutron = () => {

    const [balance, setBalance] = useState([]);

    const totalFinalBalance = balance.reduce((accumulator, transaction) => {
      return accumulator + transaction.finalBalance;
    }, 0);
  
    const formattedBalance = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(totalFinalBalance);
  
    console.log(formattedBalance);
  
  
    useEffect(() => {
      // Fetch data from backend
  
      const userid = JSON.parse(localStorage.getItem("userdata"));
  
      axios
        .get(`${url}/funding/fetchbalance`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${userid.token}`,
          },
        })
        .then((response) => {
          setBalance(response.data.balance);
        })
        .catch((error) => {
          console.error("Error fetching user data:", error);
        });
    }, []);

    
  return (
    <div className="px-[10px] py-[10px] flex gap-6 ">
      <Dashboardsidenav />
      <Transactbody totalbalancetransact= {formattedBalance} transactions={balance} />
    </div>
  );
};

export default Jumbutron;
