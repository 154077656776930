import React, { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../src/img/logo.png";

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="h-[80px] bg-[#00001A] flex px-4 justify-between items-center">
      <div className="mt-[-20px]">
        <img src={Logo} alt="logo" className="h-10 w-auto" />
      </div>
      <div className="hidden md:flex text-white justify-between gap-8">
        <Link
          to="/"
          className="text-[14px] font-[500] leading-[19px] text-[#D4F5F9] font-['Istok']"
        >
          Home
        </Link>
        <Link
          to="/aboutus"
          className="text-[14px] font-[500] leading-[19px] text-[#D4F5F9] font-['Istok']"
        >
          About Us
        </Link>
        <Link to="/contactus" className="text-[14px] font-[500] leading-[19px] text-[#D4F5F9] font-['Istok']">
          Contact Us
        </Link>
        <Link
          to="/privacy"
          className="text-[14px] font-[500] leading-[19px] text-[#D4F5F9] font-['Istok']"
        >
          Privacy Policy
        </Link>
      </div>
      <div className="hidden md:flex text-white justify-between gap-2">
        <Link
          to="/login"
          className="text-[12px] font-[500] leading-[19px] text-[#000] h-[38px] w-[120px] bg-[#D9D9D9] items-center justify-center flex rounded-[40px] font-['Istok']"
        >
          Log In
        </Link>
        <Link  to="/signup" className="text-[12px] font-[500] leading-[19px] text-[#000] h-[38px] w-[120px] bg-[#0096C7] items-center justify-center flex rounded-[40px] font-['Istok']">
          Sign Up
        </Link>
      </div>
      <div className="md:hidden flex items-center">
        <button
          className="text-white focus:outline-none"
          onClick={() => setIsOpen(!isOpen)}
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16m-7 6h7"
            />
          </svg>
        </button>
      </div>
      {isOpen && (
        <div className="absolute top-[80px] left-0 w-full bg-[#00001A]  gap-6 flex flex-col items-center text-white pb-[20px]">
          <Link
            to="/"
            className="py-2 text-[14px] font-[500] text-[#D4F5F9] font-['Istok']"
          >
            Home
          </Link>
          <Link
            to="/aboutus"
            className="py-2 text-[14px] font-[500] text-[#D4F5F9] font-['Istok']"
          >
            About Us
          </Link>
          <Link to="/contactus" className="py-2 text-[14px] font-[500] text-[#D4F5F9] font-['Istok']">
            Contact Us
          </Link>
          <Link
            to="/privacy"
            className="py-2 text-[14px] font-[500] text-[#D4F5F9] font-['Istok']"
          >
            Privacy Policy
          </Link>
          <Link
            to="/login"
            className="py-2 text-[12px] font-[500] text-[#000] bg-[#D9D9D9] w-[120px] flex items-center justify-center rounded-[40px] font-['Istok']"
          >
            Log In
          </Link>
          <Link to="/signup" className="py-2 text-[12px] font-[500] text-[#000] bg-[#0096C7] w-[120px] flex items-center justify-center rounded-[40px] font-['Istok']">
            Sign Up
          </Link>
        </div>
      )}
    </div>
  );
}

export default Navbar;
