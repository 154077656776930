import React, { useState } from "react";
import Navbar from "../component/Navbar";
import { Link } from "react-router-dom";
import Webimagefooter from "../component/Login/Webimagefooter";
import { useNavigate } from "react-router-dom";
import Activityindicator from "../Activityindicator";
import { url } from "../api/api";
import axios from "axios";

const Login = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [isLoading, setisLoading] = useState(false);
  const [error, seterrorStatus] = useState("");
  const navigate = useNavigate();

  console.log(error);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setisLoading(true);

    const newData = {
      email: formData.email,
      password: formData.password,
      isAdmin: false,
    };
    await axios
      .post(`${url}/users/signin`, newData, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        // Process the successful response here

        setisLoading(false);
        localStorage.setItem("userdata", JSON.stringify(response.data));

        navigate("/dashboard");
      })
      .catch((error) => {
        // Handle error responses here
        if (error.response) {
          if (error.response.status === 401) {
            seterrorStatus("Invalid email or password");
            setisLoading(false);
          } else if (error.response.status === 422) {
            setisLoading(false);
            localStorage.setItem("useridkey", error.response.data.userid);

            navigate("/verifyemail");
          } else {
            seterrorStatus("Unknown error exist while submiting form");
            setisLoading(false);
          }
        } else {
          console.error("Error:", error.message);
          setisLoading(false);
        }
      });
  };

  return (
    <div className="w-full">
      <Navbar />

      <div className=" w-full h-[800px]  md:h-[650px] grid place-items-center bg-[#00001A] z-50">
        <div className=" bg-[#0096C7] text-white rounded-lg shadow-lg p-8 max-w-sm w-full">
          <div className="px-4 py-4">
            <h2 className="text-[28px] font-bold mb-6 text-center text-[#00001A] font-[istok]">
              Login to your Account
            </h2>
            <p className=" text-[14px]  text-blue-200  font-[istok] mt-[-25px] text-center">
              Securely login to your Emerald Capital account{" "}
            </p>
          </div>

          <form onSubmit={handleSubmit} className="space-y-4 mt-[30px]">
            {error && <h2 className="text-red-500"> {error}</h2>}
            <div>
              <label
                htmlFor="email"
                className="block text-[12px] font-medium mb-1 text-black"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                className="w-full px-3 py-2 border border-black bg-[#0096C7] rounded-[10px] "
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div>
              <label
                htmlFor="password"
                className="block text-[12px] font-medium mb-1 text-black"
              >
                Password
              </label>
              <input
                type="password"
                id="password"
                className="w-full px-3 py-2 bg-[#0096C7]  border border-black rounded-[10px]"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
              />
            </div>

            <br />
            <Link to="/forgotpass" className=" text-white text-[14px] mt-[20px]">
              Forgot Password
            </Link>

            <p className=" text-black text-[16px] font-[istok]">
              By Creating an account, I agree to Emerald Capital’s terms and
              condition
            </p>

            <br />

            <div className=" mt-[146px]">
              {isLoading ? (
                <Activityindicator />
              ) : (
                <button
                  type="submit"
                  className="  font-[istok] w-full py-4 px-4 bg-white rounded-md  focus:outline-none focus:ring-2 focus:ring-black text-black"
                >
                  Login
                </button>
              )}
            </div>

            <p className=" text-black font-[istok] mt-[20px] ">
              Don’t have an account?{" "}
              <Link to="/signup" className="text-[#fff] font-[istok]">
                Sign Up Here
              </Link>
            </p>
            <p className=" text-black font-[istok] ">
              Forgot password?{" "}
              <Link className="text-[#fff] font-[istok]"> Click Here</Link> we
              can help
            </p>
          </form>
        </div>
      </div>

      <Webimagefooter />
    </div>
  );
};

export default Login;
