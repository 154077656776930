import React from "react";
import Profilepage from "../component/Profile/Profilepage";
import Navbar from "../component/Dasboard/Navbar";
import Dashboardsidenav from "../component/Dasboard/Dashboardsidenav";
import Heading from "../component/Dasboard/Heading";

const Profile = () => {
  return (
    <div className="md:h-[1100px] bg-[#00001A]">
      <Navbar />
      <Heading />
      <div className="px-[10px] py-[10px] flex gap-6 ">
        <Dashboardsidenav />
        <Profilepage />
      </div>
    </div>
  );
};

export default Profile;
