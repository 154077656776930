import React from "react";
import "./Dashboard.css";
import CryptoChart from "./CryptoChart";
import Cryptolisting from "./Cryptolisting";
import { FaArrowAltCircleDown } from "react-icons/fa";

const Dashboardbody = ({ totalbalance, handleOpenPopup5 }) => {
  return (
    <div className="h-[400px] w-[600px] rounded-[15px] border-[1px] border-customWhite px-4 py-4">
      <div className="flex">
        <div className="px-4 py-4">
          <h2 className="text-[32px] text-[#D4F5F9]">Total Balance </h2>
          <p className="text-[32px] text-customText1 text-grid font-[700] font-[istok]">
            {totalbalance}
          </p>

          <div
            onClick={handleOpenPopup5}
            className="flex h-[60px] cursor-pointer mt-4 text-[#fff] text-[20px] gap-1 justify-between items-center px-10 py-10 rounded-md border border-white"
          >
            {" "}
            <FaArrowAltCircleDown /> Withdrawal{" "}
          </div>
        </div>
        <div className="h-[400px]  mt-[60px]">
          <CryptoChart />
        </div>
      </div>

      <Cryptolisting />
    </div>
  );
};

export default Dashboardbody;
