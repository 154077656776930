import React from "react";

import Mobileheader from "../component/Mobiledashboard/Mobileheader";
import Mobilenav from "../component/Mobiledashboard/Mobilenav";


const Mobilecontactus = () => {


  return (
    <div className="h-[1000px] bg-[#00001A] px-6">
      <Mobileheader className="mt-[30px]"/>
      <div className="px-6 py-6 mt-10  grid gap-12">
        <h2 className="text-white text-[30px] font-[istok] font-[900]">Contact Us</h2>

        <h2 className="text-[#D4F5F9] text-[25px] font-[istok] font-[900]">Do you have any pending issues regarding payment , withdrawal or investment ? Do not hesitate to reach out to us.</h2>

        <p className=" text-justify text-white">
         EMAIL: SALES@emeraldcapitalhq.com
        </p>


        <p className=" text-justify text-white">
         OFFICE ADDRESS: PO BOX 200122 Great Britian Avenue UNITED KINGDOM.
        </p>


      </div>
      <Mobilenav />
    </div>
  );
};

export default Mobilecontactus;
