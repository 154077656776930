import React, {useState, useEffect} from "react";
import Dashboardsidenav from "../Dasboard/Dashboardsidenav";

import "react-toastify/dist/ReactToastify.css";
import Transactbody from "./Transactbody";
import axios from "axios";
import { url } from "../../api/api";



const Jumbutron = () => {

    const [investment, setInvestment] = useState([]);

    useEffect(() => {
      // Fetch data from backend
  
      const userid = JSON.parse(localStorage.getItem("userdata"));
  
      axios
        .get(`${url}/investment/fetchsingleinvestment/${userid._id}`, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          setInvestment(response.data.investment);
        })
        .catch((error) => {
          console.error("Error fetching user data:", error);
        });
    }, []);

    
  return (
    <div className="px-[10px] py-[10px] flex gap-6 ">
      <Dashboardsidenav />
      <Transactbody investmented= {investment} />
    </div>
  );
};

export default Jumbutron;
