import React from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ element, ...rest }) => {
  const usertoken = JSON.parse(localStorage.getItem("userdata"));

  // If usertoken doesn't exist, redirect to the login page
  if (!usertoken) {
    return <Navigate to="/" />;
  }

  // Otherwise, render the requested component
  return element;
};

export default ProtectedRoute;
