// src/components/CryptoChart.js
import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import axios from 'axios';
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  PointElement // Add this import
} from 'chart.js';

// Register chart components
ChartJS.register(
  LineElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  PointElement // Register the PointElement
);

const CryptoChart = () => {
  const [data, setData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Bitcoin Price',
        data: [],
        borderColor: 'rgba(75,192,192,1)',
        backgroundColor: 'rgba(75,192,192,0.2)',
      },
    ],
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          'https://api.coingecko.com/api/v3/coins/bitcoin/market_chart?vs_currency=usd&days=30'
        );
        const prices = response.data.prices;

        if (Array.isArray(prices)) {
          const labels = prices.map(price => new Date(price[0]).toLocaleDateString());
          const chartData = prices.map(price => price[1]);

          setData({
            labels,
            datasets: [
              {
                label: 'Bitcoin Price',
                data: chartData,
                borderColor: 'rgba(75,192,192,1)',
                backgroundColor: 'rgba(75,192,192,0.2)',
              },
            ],
          });
        } else {
          console.error('Unexpected API response structure:', prices);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <h2>Bitcoin Price Chart</h2>
      <Line data={data} />
    </div>
  );
};

export default CryptoChart;
